section {
  position: relative;
}

.section-heading {
  font-size: 2rem;
  text-align: center;
  padding: 2rem 0;
  color: $light-secondary;
  opacity: 0;
  animation: fadeIn linear forwards;
  animation-timeline: view();
  animation-range: 50px 300px;
}

.light-section {
  background-color: $dark-secondary;
  color: $light-primary;
  padding: 3rem 2rem;
}

.dark-section {
  background-color: $dark-secondary;
  color: $light-secondary;
  padding: 2rem;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
