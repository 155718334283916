$light-primary: #32bcc1;
$light-primary-translucent: rgba(50, 188, 193, 0.85);
$light-primary-translucent-heavy: rgba(50, 188, 193, 0.45);
$light-secondary: #d9b310;
$light-secondary-translucent: rgba(217, 179, 16, 0.45);
$dark-primary: #0b3c5d;
$dark-primary-translucent: #3d60779c;
$dark-secondary: #1d2731;
$dark-accent: #d3d8f1;
$primary-font: 'merriweather', serif;
$secondary-font: 'merriweather-sans', sans-serif;
$border-radius: 9px;
