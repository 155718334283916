#social-section {
  display: flex;
  padding: 50px 0;
  text-align: center;
  justify-content: center;
}

#social-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  font-size: 2rem;

  i {
    font-size: 2rem;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: $light-primary;
    }
  }
}
