@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Merriweather+Sans&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "merriweather", serif;
  text-decoration: none;
}

body {
  background-color: #1d2731;
}

button {
  cursor: pointer;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

div .btn {
  background-color: #0b3c5d;
  font-family: "merriweather", serif;
  font-size: 1rem;
  color: #d9b310;
  border-radius: 9px;
  border-style: solid;
  border-color: #32bcc1;
  cursor: pointer;
  width: 100%;
}

div .btn:hover {
  background-color: #1d2731;
}

h3 {
  font-size: 2rem;
}

.container {
  width: 80vw;
  margin: 0 auto;
  background-color: #1d2731;
  color: #32bcc1;
  border: #d9b310 solid 2px;
}

section {
  position: relative;
}

.section-heading {
  font-size: 2rem;
  text-align: center;
  padding: 2rem 0;
  color: #d9b310;
  opacity: 0;
  animation: fadeIn linear forwards;
  animation-timeline: view();
  animation-range: 50px 300px;
}

.light-section {
  background-color: #1d2731;
  color: #32bcc1;
  padding: 3rem 2rem;
}

.dark-section {
  background-color: #1d2731;
  color: #d9b310;
  padding: 2rem;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
nav {
  position: fixed;
  z-index: 1;
  width: 100vw;
  background-color: #d9b310;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #0b3c5d;
}
nav #logo-container {
  display: flex;
  align-items: center;
  width: 66%;
}
nav #logo-container #logo {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
nav #logo-container #logo p {
  width: 100%;
  font-size: 2rem;
}
nav #logo-container #logo img {
  width: 3rem;
  border-radius: 9px;
  border: #23db99 solid 1px;
}
nav .dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#nav-list {
  display: flex;
  justify-content: space-between;
}
#nav-list li {
  width: 100px;
  text-align: center;
  font-size: 2rem;
  padding: 1rem;
  line-height: 2;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s;
}
#nav-list li a {
  width: 100%;
  height: 100%;
  font-size: large;
  font-family: "merriweather-sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
#nav-list li:hover {
  background-color: #1d2731;
  color: #32bcc1;
}
#nav-list .dropdown-content a {
  padding: 1rem;
}
#nav-list .dropdown-content a:last-child {
  padding: 0.5rem 2rem;
  text-align: center;
}

@media (max-width: 850px) {
  nav {
    padding: 0 0 1rem 0;
  }
  nav .dropdown {
    padding-top: 1rem;
  }
  #nav-header {
    display: flex;
    justify-content: space-between;
  }
  #nav-header p {
    font-size: 1.5rem;
    text-align: center;
    cursor: pointer;
  }
  #nav-list {
    display: flex;
    flex-direction: column;
    top: 150px;
    left: 0;
    height: 380px;
    width: 100vw;
    opacity: 1;
    z-index: 1;
  }
  #nav-list li {
    width: 100%;
    height: 75px;
    text-align: left;
    justify-content: left;
  }
  #nav-list li a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  #nav-list.hidden {
    top: 0;
    visibility: hidden;
    padding: 0;
    height: 0;
    opacity: 0;
  }
  .toggle {
    background: transparent;
    position: relative;
    z-index: 3;
    cursor: pointer;
    width: 40px;
    height: 45px;
    display: flex;
    border: none;
    margin-top: 5px;
    margin-left: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .toggle span {
    position: absolute;
    display: block;
    height: 6px;
    width: 100%;
    background-color: #0b3c5d;
    border-radius: 9px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .toggle span:nth-child(1) {
    top: 0px;
  }
  .toggle span:nth-child(2),
  .toggle span:nth-child(3) {
    top: 18px;
  }
  .toggle span:nth-child(4) {
    top: 36px;
  }
  .toggle.open span:nth-child(1), .toggle.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  .toggle.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .toggle.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .toggle:hover {
    background-color: transparent;
  }
}
@media (min-width: 850px) {
  .toggle {
    display: none;
  }
  nav {
    padding: 0 2rem;
  }
  nav .dropdown {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  nav #logo-container {
    width: 100%;
  }
}
#about {
  width: 80vw;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.tony-img {
  border-radius: 50%;
  border: 5px solid #d9b310;
  width: 25vw;
  height: 25vw;
}

.sub-heading {
  font-size: 2.5rem;
  font-family: "merriweather-sans", sans-serif;
  text-align: center;
}

.about-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.about-container .heading-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  width: 100%;
}

.about-container .heading-container .sub-heading {
  font-size: 1.5rem;
  text-align: left;
}

.name {
  font-size: 4rem;
}

.about-me {
  padding: 2rem 0;
  font-size: 1rem;
  font-family: "merriweather-sans", sans-serif;
  font-weight: lighter;
  color: rgba(50, 188, 193, 0.85);
}

#skills-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  text-align: left;
}

#skills-heading {
  font-size: 1rem;
  font-weight: bold;
}

.skills-list {
  list-style: none;
  color: rgba(50, 188, 193, 0.85);
}
.skills-list li {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  font-family: "merriweather-sans", sans-serif;
}

.icon {
  color: #d9b310;
  font-size: 1rem;
  padding-right: 20px;
  width: 1rem;
}

@media (max-width: 850px) {
  #about {
    justify-content: center;
  }
  .about-container {
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
  }
  .about-container .tony-img {
    width: 33vw;
    height: 33vw;
  }
  .about-container .heading-container {
    padding: 1rem;
    text-align: center;
  }
  .about-container .heading-container .name {
    font-size: 3rem;
  }
  .about-container .heading-container .sub-heading {
    font-size: 1.5rem;
    text-align: center;
  }
  #skills-wrapper {
    display: flex;
    gap: 1rem;
    justify-content: center;
    text-align: left;
  }
}
.card {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 #0b3c5d;
  transition: background-color 0.5s;
}
.card:hover {
  background-color: rgba(61, 96, 119, 0.6117647059);
}
.card .card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.card .card-content h2 {
  font-size: 1.5rem;
  font-weight: lighter;
  color: #d9b310;
}
.card p {
  font-size: 1rem;
  font-weight: lighter;
  color: rgba(50, 188, 193, 0.85);
  font-family: "merriweather-sans", sans-serif;
}
.card ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
  list-style: none;
  margin: 0;
}

img,
video {
  object-fit: cover;
  border-radius: 10px;
  border: 5px solid #d9b310;
  transition: transform 0.5s;
  cursor: pointer;
}

.tech-item {
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "merriweather-sans", sans-serif;
  color: #32bcc1;
  background-color: rgba(50, 188, 193, 0.45);
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: 1px solid #d9b310;
  border-radius: 15px;
}

#featured {
  display: grid;
  grid-template-columns: 40vw 40vw;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
#featured img,
#featured video {
  width: 500px;
  height: 100%;
}

#other {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
#other .card {
  justify-content: space-between;
  width: 80vw;
  gap: 2rem;
  padding: 20px;
}
#other img,
#other video {
  width: 15vw;
  height: 100%;
}

.card-links {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 850px) {
  #featured,
  #other {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  #featured .card,
  #other .card {
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    width: 100%;
  }
  #featured img,
  #featured video,
  #other img,
  #other video {
    width: 100%;
  }
  #other img {
    width: 100%;
  }
}
@media (min-width: 850px) {
  #featured {
    display: grid;
    grid-template-columns: 40vw 40vw;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  #featured .card {
    flex-direction: column;
    width: 37vw;
    height: 37vw;
    gap: 1rem;
    padding: 20px;
  }
  #featured p {
    height: 3rem;
  }
  #featured img,
  #featured video {
    height: 20vw;
  }
}
#featured .card {
  transform: translateY(150px);
  opacity: 0;
  animation: fadeInFeature linear forwards;
  animation-timeline: view();
  animation-range: 200px 450px;
}

@keyframes fadeInFeature {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
#other .card {
  transform: translateX(-50vw);
  opacity: 0;
  animation: fadeInOther linear forwards;
  animation-timeline: view();
  animation-range: 100px 300px;
}

@keyframes fadeInOther {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80vw;
  margin: 0 auto;
  gap: 0.5rem;
  border-radius: 10px;
  background-color: transparent;
  padding: 0.2rem;
}
.contact-form button {
  background-color: #d9b310;
  color: #0b3c5d;
  font-size: 1rem;
  font-family: "merriweather-sans", sans-serif;
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
}

.form-control {
  color: #d3d8f1;
  border-radius: 10px;
  border: 1px solid #d9b310;
  font-family: "merriweather-sans", sans-serif;
  padding: 0.5rem;
  background-color: transparent;
}
.form-control::placeholder {
  color: rgba(217, 179, 16, 0.45);
}

#message {
  height: 7rem;
}

#social-section {
  display: flex;
  padding: 50px 0;
  text-align: center;
  justify-content: center;
}

#social-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  font-size: 2rem;
}
#social-bar i {
  font-size: 2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}
#social-bar i:hover {
  color: #32bcc1;
}

/* Styles for the modal */
.modal {
  position: fixed;
  top: auto;
  left: 50%;
  right: auto;
  bottom: 50%;
  transform: translate(-50%, 50%);
  padding: 2rem;
  border: 1px solid #d9b310;
  background-color: #1d2731;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsla(0, 0%, 0%, 0.1);
  flex-direction: column;
  align-items: stretch;
  color: #d9b310;
}

.modal-header {
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-body {
  flex-grow: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: "merriweather-sans", sans-serif;
}

.modal-close-btn {
  font-size: 1em;
  background-color: #d9b310;
  width: 100%;
  padding: 0.5em;
  border-radius: 0.5em;
  border: 1px solid #1d2731;
  color: #1d2731;
}

.modal::backdrop {
  background: hsla(0, 0%, 0%, 0.5);
}

