.card {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 $dark-primary;
  transition: background-color 0.5s;

  &:hover {
    background-color: $dark-primary-translucent;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      font-weight: lighter;
      color: $light-secondary;
    }
  }

  p {
    font-size: 1rem;
    font-weight: lighter;
    color: $light-primary-translucent;
    font-family: $secondary-font;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    list-style: none;
    margin: 0;
  }
}

img,
video {
  object-fit: cover;
  border-radius: 10px;
  border: 5px solid $light-secondary;
  transition: transform 0.5s;
  cursor: pointer;
}

.tech-item {
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: $secondary-font;
  color: $light-primary;
  background-color: $light-primary-translucent-heavy;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: 1px solid $light-secondary;
  border-radius: 15px;
}

#featured {
  display: grid;
  grid-template-columns: 40vw 40vw;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  img,
  video {
    width: 500px;
    height: 100%;
  }
}

#other {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  .card {
    justify-content: space-between;
    width: 80vw;
    gap: 2rem;
    padding: 20px;
  }

  img,
  video {
    width: 15vw;
    height: 100%;
  }
}

.card-links {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 850px) {
  #featured,
  #other {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    .card {
      flex-direction: column;
      gap: 1rem;
      padding: 20px;
      width: 100%;
    }

    img,
    video {
      width: 100%;
    }
  }

  #other {
    img {
      width: 100%;
    }
  }
}

@media (min-width: 850px) {
  #featured {
    display: grid;
    grid-template-columns: 40vw 40vw;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    .card {
      flex-direction: column;
      width: 37vw;
      height: 37vw;
      gap: 1rem;
      padding: 20px;
    }

    p {
      height: 3rem;
    }

    img,
    video {
      height: 20vw;
    }
  }
}

#featured .card {
  transform: translateY(150px);
  opacity: 0;
  animation: fadeInFeature linear forwards;
  animation-timeline: view();
  animation-range: 200px 450px;
}

@keyframes fadeInFeature {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
#other .card {
  transform: translateX(-50vw);
  opacity: 0;
  animation: fadeInOther linear forwards;
  animation-timeline: view();
  animation-range: 100px 300px;
}

@keyframes fadeInOther {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
