* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $primary-font;
  text-decoration: none;
}

body {
  background-color: $dark-secondary;
}