nav {
  position: fixed;
  z-index: 1;
  width: 100vw;
  background-color: $light-secondary;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: $dark-primary;

  #logo-container {
    display: flex;
    align-items: center;
    width: 66%;

    #logo {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      p {
        width: 100%;
        font-size: 2rem;
      }

      img {
        width: 3rem;
        border-radius: $border-radius;
        border: #23db99 solid 1px;
      }
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#nav-list {
  display: flex;
  justify-content: space-between;

  li {
    width: 100px;
    text-align: center;
    font-size: 2rem;
    padding: 1rem;
    line-height: 2;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s;

    a {
      width: 100%;
      height: 100%;
      font-size: large;
      font-family: $secondary-font;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  li:hover {
    background-color: $dark-secondary;
    color: $light-primary;
  }

  .dropdown-content a {
    padding: 1rem;
  }

  .dropdown-content a:last-child {
    padding: 0.5rem 2rem;
    text-align: center;
  }
}

@media (max-width: 850px) {
  nav {
    padding: 0 0 1rem 0;

    .dropdown {
      padding-top: 1rem;
    }
  }

  #nav-header {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1.5rem;
      text-align: center;
      cursor: pointer;
    }
  }

  #nav-list {
    display: flex;
    flex-direction: column;
    top: 150px;
    left: 0;
    height: 380px;
    width: 100vw;
    opacity: 1;
    z-index: 1;

    li {
      width: 100%;
      height: 75px;
      text-align: left;
      justify-content: left;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
      }
    }

    &.hidden {
      top: 0;
      visibility: hidden;
      padding: 0;
      height: 0;
      opacity: 0;
    }
  }

  .toggle {
    background: transparent;
    position: relative;
    z-index: 3;
    cursor: pointer;
    width: 40px;
    height: 45px;
    display: flex;
    border: none;
    margin-top: 5px;
    margin-left: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;

    span {
      position: absolute;
      display: block;
      height: 6px;
      width: 100%;
      background-color: $dark-primary;
      border-radius: $border-radius;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 18px;
    }

    span:nth-child(4) {
      top: 36px;
    }

    &.open span:nth-child(1),
    &.open span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    &.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .toggle:hover {
    background-color: transparent;
  }
}

@media (min-width: 850px) {
  .toggle {
    display: none;
  }

  nav {
    padding: 0 2rem;

    .dropdown {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    #logo-container {
      width: 100%;
    }
  }
}
