.modal {
  position: fixed;
  top: auto;
  left: 50%;
  right: auto;
  bottom: 50%;
  transform: translate(-50%, 50%);
  padding: 2rem;
  border: 1px solid $light-secondary;
  background-color: $dark-secondary;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  flex-direction: column;
  align-items: stretch;
  color: $light-secondary;
}

.modal-header {
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-body {
  flex-grow: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: $secondary-font;
}

.modal-close-btn {
  font-size: 1em;
  background-color: $light-secondary;
  width: 100%;
  padding: 0.5em;
  border-radius: 0.5em;
  border: 1px solid $dark-secondary;
  color: $dark-secondary;
}

.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
}
